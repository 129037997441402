import { Injectable } from '@angular/core';
import { Comment, InspectionCorrectionTypeCd } from '@xpo-ltl/sdk-common';
import { PieceDimensions } from '@xpo-ltl/sdk-inspections';
import { ProNumber } from '../classes/pronumber';
import { DmsOpCode } from '../enums/dms-op-code.enum';
import { ListName } from '../enums/list-name.enum';
import { AppStorageService } from './app-storage.service';

@Injectable()
export class InspectionLocalStorageService {
  private readonly INSPECTION_NOTES = 'InspectionNotes-';
  private readonly INSPECTION_DIMENSIONS = 'InspectionDimensions-';
  private readonly INSPECTION_WEIGHT = 'InspectionWeight-';
  private readonly INSPECTION_CORRECTION_TYPE_CODE = 'InsCorrectionTypeCd-';
  private readonly ROUTING_HISTORY = 'RoutingHistory';
  private readonly PRO_INDEX = 'proIndex';
  private readonly CORRECTION_INDEX = 'correctionIndex';
  private readonly LAST_VISITED_LIST = 'LastVisitedList';

  constructor(private storageService: AppStorageService) {}

  clearInspectionData(proNumber: ProNumber, deletePhotosFlag: boolean = false) {
    if (proNumber) {
      localStorage.removeItem(this.INSPECTION_NOTES + proNumber.formatProNumber());
      localStorage.removeItem(this.INSPECTION_DIMENSIONS + proNumber.formatProNumber());
      localStorage.removeItem(this.INSPECTION_WEIGHT + proNumber.formatProNumber());
      localStorage.removeItem(this.INSPECTION_CORRECTION_TYPE_CODE + proNumber.formatProNumber());
      if (deletePhotosFlag) {
        this.storageService.deletePhotosByProAndStatusCd(proNumber, DmsOpCode.NEWLY_ADDED_PHOTO).subscribe();
      }
    }
  }

  setInspectionNotes(proNumber: ProNumber, inspectionNotes: Comment) {
    if (proNumber) {
      localStorage.setItem(this.INSPECTION_NOTES + proNumber.formatProNumber(), JSON.stringify(inspectionNotes));
    }
  }

  setInspectionCorrectionTypeCd(proNumber: ProNumber, typeCode: InspectionCorrectionTypeCd[]) {
    if (proNumber) {
      localStorage.setItem(
        this.INSPECTION_CORRECTION_TYPE_CODE + proNumber.formatProNumber(),
        JSON.stringify(typeCode)
      );
    }
  }

  setInspectionDimensions(proNumber: ProNumber, inspectionDimensions: PieceDimensions[]) {
    if (proNumber) {
      localStorage.setItem(
        this.INSPECTION_DIMENSIONS + proNumber.formatProNumber(),
        JSON.stringify(inspectionDimensions)
      );
    }
  }

  setInspectionWeight(proNumber: ProNumber, inspectionWeight: number) {
    if (proNumber) {
      localStorage.setItem(this.INSPECTION_WEIGHT + proNumber.formatProNumber(), inspectionWeight.toString());
    }
  }

  setLastVisitedList(lastVisitedList: ListName) {
    if (lastVisitedList) {
      localStorage.setItem(this.LAST_VISITED_LIST, lastVisitedList);
    }
  }

  setRoutingHistory(histories: string[]) {
    localStorage.setItem(this.ROUTING_HISTORY, histories?.toString());
  }

  setLastProIndexClick(index: string) {
    localStorage.setItem(this.PRO_INDEX, index);
  }

  setLastCorrectionIndexClick(index: string) {
    localStorage.setItem(this.CORRECTION_INDEX, index);
  }

  getInspectionCorrectionTypeCd(proNumber: ProNumber): InspectionCorrectionTypeCd[] {
    let inspectionCorrectionTypeCd;
    if (proNumber) {
      const json = localStorage.getItem(this.INSPECTION_CORRECTION_TYPE_CODE + proNumber.formatProNumber());
      if (json) {
        inspectionCorrectionTypeCd = JSON.parse(json);
      }
    }

    return inspectionCorrectionTypeCd;
  }

  getInspectionNotes(proNumber: ProNumber): Comment {
    let comment: Comment;
    if (proNumber) {
      const json = localStorage.getItem(this.INSPECTION_NOTES + proNumber.formatProNumber());
      if (json) {
        comment = JSON.parse(json);
      }
    }
    return comment;
  }

  getInspectionDimensions(proNumber: ProNumber): PieceDimensions[] | undefined {
    let result: PieceDimensions[] | undefined;
    if (proNumber) {
      const json = localStorage.getItem(this.INSPECTION_DIMENSIONS + proNumber.formatProNumber());
      if (json) {
        result = JSON.parse(json);
      }
    }
    return result;
  }

  getInspectionWeight(proNumber: ProNumber): number | undefined {
    let weight: number | undefined;

    if (proNumber) {
      const weightS: string = localStorage.getItem(this.INSPECTION_WEIGHT + proNumber.formatProNumber());
      if (weightS?.length > 0) {
        weight = +weightS;
      }
    }

    return weight;
  }

  getLastVisitedList(): ListName {
    return localStorage.getItem(this.LAST_VISITED_LIST) as ListName;
  }

  getRoutingHistory(): string[] {
    const routingHistory: string = localStorage.getItem(this.ROUTING_HISTORY);
    let routingHistories: string[] = [];
    if (routingHistory?.trim().length > 0) {
      routingHistories = routingHistory.split(',');
    }

    return routingHistories;
  }

  getLastProIndex(): string {
    return localStorage.getItem(this.PRO_INDEX);
  }

  getLastCorrectionIndex(): string {
    return localStorage.getItem(this.CORRECTION_INDEX);
  }

  removeInspectionCorrectionTypeCd(proNumber: ProNumber) {
    if (proNumber) {
      localStorage.removeItem(this.INSPECTION_CORRECTION_TYPE_CODE + proNumber.formatProNumber());
    }
  }
}
