import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CorrectionRequest, DimensionerInspectionDetail, DimensionerInspectionDetailDoc } from '@xpo-ltl/sdk-inspections';
import { Photo } from '../../../../classes/photos/photo';
import { PhotoId } from '../../../../classes/photos/photo-id';
import { DmsOpCode } from '../../../../enums/dms-op-code.enum';
import { ProNumber } from '../../../../classes/pronumber';
import { BehaviorSubject, catchError, forkJoin, map, of, Subject, take, takeUntil } from 'rxjs';
import { DocumentManagementService } from '../../../../services/api/document-management-service';
import { DmsDocType } from '../../../../enums/dms-doc-type.enum';
import { List } from 'immutable';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { PdfViewerData, PdfViewerDialogComponent } from '../../../../components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { ShipmentDetailsComponent } from '../../../../components/shipment-details/shipment-details.component';
import { CorrectionRequestReviewStatusCd } from '@xpo-ltl/sdk-common';

interface DocumentWithPhoto {doc: DimensionerInspectionDetailDoc, photo: Photo}

@Component({
  selector: 'app-dimensioner-inspection-detail',
  templateUrl: './dimensioner-inspection-detail.component.html',
  styleUrls: ['./dimensioner-inspection-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionerInspectionDetailComponent implements OnChanges {
  @Input()
  dimensionerInspectionDetailInput: DimensionerInspectionDetail;
  @Input()
  correctionRequest: CorrectionRequest;
  @Output() onRedimChanged = new EventEmitter<any>();

  checkAllBehaviorSubject = new BehaviorSubject<any>(undefined);

  dimensionerInspectionDetail$ = new BehaviorSubject<DimensionerInspectionDetail>(null);
  matPanelTitleContent$ = new BehaviorSubject<string>(null);
  documentWithPhotoArray$ = new BehaviorSubject<List<DocumentWithPhoto>>(List());
  isExpanded = true;
  private documentsDialogRef;
  matDialogRef: any;
  redim: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    private documentManagementService: DocumentManagementService,
    private matDialog: MatDialog,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dimensionerInspectionDetailInput']) {
      const dimensionerInspectionDetailId = this.dimensionerInspectionDetailInput?.dimensionerInspectionDetailId;
      const childProNbr = this.dimensionerInspectionDetailInput?.childProNbr;
      let detailIdTitle = '';
      let childProNbrTitle = '';
      let titlePipe = '';
      
      if(dimensionerInspectionDetailId) {
        detailIdTitle = `Detail ID - ${dimensionerInspectionDetailId}`;
      }

      if(childProNbr) {
        childProNbrTitle = `Child PRO - ${childProNbr}`;
      }
      
      if(detailIdTitle && childProNbrTitle) {
        titlePipe = ' | ';
      }

      this.dimensionerInspectionDetail$.next(this.dimensionerInspectionDetailInput);
      this.matPanelTitleContent$.next(`${detailIdTitle}${titlePipe}${childProNbrTitle}`);
      this.loadArchivedDocuments();
    }
  }

  redimChecked(event){
    this.redim = event.checked;
    this.onRedimChanged.emit(event.checked);
  }

  redimAllChanged(checked: boolean){
    this.redim = checked;
    this.cdRef.detectChanges();
  }
  
  isRedimAllowed() {
    return this.correctionRequest.reviewStatusCd === CorrectionRequestReviewStatusCd.IN_REVIEW
  }
  
  loadArchivedDocuments() {
    const observableBatch = this.dimensionerInspectionDetailInput?.dimensionerInspectionDetailDoc.map((doc: DimensionerInspectionDetailDoc) => {
      return this.documentManagementService.getArchivedDdfoDocument(doc.dimensionDocId).pipe(
        map((archivedDoc:any) => {
          const photoId = new PhotoId(new ProNumber(doc.dimensionDocId), DmsDocType.DOCK_DIMENSIONER_FOTO);
          const photo = new Photo(photoId);
          photo.dmsOpCode = DmsOpCode.NEWLY_ADDED_PHOTO;
          photo.setBase64dataAndContentType(archivedDoc?.document?.binary, Photo.CONTENT_TYPE_PNG);
          return {photo, doc} ;
        }),
        catchError(error => of('error getting photo others may still be available'))
      )
    });
  
    forkJoin(observableBatch).pipe(take(1)).subscribe((res: DocumentWithPhoto[]) => this.documentWithPhotoArray$.next(List(res)));
  }


  imagesClick() {
    let documentsIdStrings: string[] = [];
    this.documentWithPhotoArray$.subscribe((documentsWithPhoto) => {
      documentsWithPhoto?.forEach((documentWithPhoto) => {
        documentsIdStrings.push(documentWithPhoto?.doc?.dimensionDocId)
      })
    });
    if (this.documentsDialogRef) {
      // there's an existing one open.
      this.documentsDialogRef.close();
    }

    const pdfViewerData: PdfViewerData = {
      pro: this.correctionRequest.proNbr, 
      documentTypes: ShipmentDetailsComponent.INSPECTIONS_DOC_TYPES,
      documentTimestamps: documentsIdStrings
    }

    const dialogClosedSubject = new Subject<void>();
    this.documentsDialogRef = this.matDialog.open(PdfViewerDialogComponent, {
      position: {
        top: '100px',
        left: '100px',
      },
      height: '880px',
      maxHeight: '1200px',

      hasBackdrop: false,
      data: pdfViewerData,
      panelClass: 'pdf-mat-dialog-panel',
    });

    this.documentsDialogRef
      .keydownEvents()
      .pipe(takeUntil(dialogClosedSubject))
      .subscribe((e: KeyboardEvent) => {
        if (e.code === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          this.documentsDialogRef.close();
        }
      });

    this.documentsDialogRef.afterClosed().subscribe(() => {
      dialogClosedSubject.next();
      dialogClosedSubject.complete();
    });
  }
}
