import { Component, EventEmitter, Inject, OnDestroy, Output, QueryList, ViewChildren,} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import { DialogAction, toDialogActionPastTense } from '../../enums/dialog-actions.enum';
import { BehaviorSubject, Subject } from 'rxjs';
import { InspectionsApiCorrectionsWrapperService } from '../../services/api/inspections-api-corrections-wrapper.service';
import { CorrectionReason, CorrectionRequest, DimensionerInspectionDetail, GetDimensionerInspectionResp, GetInspectionShipmentDetailsResp, ListCorrectionReasonsByCategoryPath } from '@xpo-ltl/sdk-inspections';
import { CorrectionReasonCategoryCd, CorrectionRequestReviewStatusCd, InspectionCorrectionTypeCd } from '@xpo-ltl/sdk-common';
import { List } from 'immutable';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { InspectionsApiWrapperService } from '../../services/inspections/inspections-api-wrapper.service';
import { InspectionShipmentDetailsService } from '../../services/api/inspection-shipment-details.service';
import { ProNumber } from '../../classes/pronumber';
import { CorrectionInformation } from '../../interfaces/correction-information.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { DialogHeader } from '../../enums/dialog-header.enum';
import { CustomerGuidelinesService } from '../../components/customer-guidelines/services/customer-guidelines.service';
import { NotificationData } from '../../classes/notification-data';
import { MultipleNotificationData } from '../../classes/multiple-notification-data';
import { CustomerInstructionsAndPalletPricingRatedDialogComponent } from '../customer-instructions-dialog/customer-instructions-dialog.component';
import { LocationService } from '../../services/location/location.service';
import { InspectionShipmentDetails } from '../../classes/inspection-shipment-details';
import { ShipmentDetailsCacheService } from '../../services/cache/shipment-details-cache.service';
import { DimensionerInspectionDetailComponent } from './components/dimensioner-inspection-detail';
import { DimensionerInspectionDetailListComponent } from './components/dimensioner-inspection-detail-list/dimensioner-inspection-detail-list.component';
import { RedimensionDialogComponent } from '../redimension-dialog/redimension-dialog.component';

@Component({
  selector: 'app-correction-approval-dialog',
  templateUrl: './correction-approval-dialog.component.html',
  styleUrls: ['./correction-approval-dialog.component.scss']
})
export class CorrectionApprovalDialogComponent implements OnDestroy {
  @Output() new75Event = new EventEmitter<{id: number}>();
  @ViewChildren(DimensionerInspectionDetailListComponent) children: QueryList<DimensionerInspectionDetailListComponent>;
  
  private unsubscriber$: Subject<void> = new Subject();
  isConfirmMessageDisplayed$ = new BehaviorSubject<boolean>(false);
  isDecline$ = new BehaviorSubject<boolean>(false);
  is75$ = new BehaviorSubject<boolean>(false);
  is75RuleDisplayed$ = new BehaviorSubject<boolean>(false);
  correctionInformation$ = new BehaviorSubject<CorrectionInformation>(undefined);
  isUnapply75RuleDisplayed$ = new BehaviorSubject<boolean>(false);
  selectedAction$ = new BehaviorSubject<DialogAction>(null);
  warningActionLabel$ = new BehaviorSubject<string>('');
  correctionRequest$ = new BehaviorSubject<CorrectionRequest>(null);
  dimensionerInspectionDetails$ = new BehaviorSubject<List<DimensionerInspectionDetail>>(List());
  inspectionShipmentDetailsRespSubject = new BehaviorSubject<GetInspectionShipmentDetailsResp>(undefined);
  shipmentDetailsSubject = new BehaviorSubject<InspectionShipmentDetails>(undefined);
  isReviewCorrectionActionExecutable$ = new BehaviorSubject<boolean>(false);
  
  title: string = "Approve Auto-Correction";
  readonly CorrectionRequestReviewStatusCd = CorrectionRequestReviewStatusCd;
  readonly DialogAction = DialogAction;
  reviewComments: string;
  correctionRequestId: number;
  declineReasons: CorrectionReason[];
  showOther: boolean = false;
  correctionFormGroup: FormGroup;
  useShipmentDetailsCache: boolean = false;
  redimEnabled:boolean = false;
  
  get selectedAction(): DialogAction {
    return this.selectedAction$.value;
  };

  constructor(
    private shipmentDetailsCacheService: ShipmentDetailsCacheService,
    private dialogRef: MatDialogRef<CorrectionApprovalDialogComponent>,
    private inspectionsApiCorrectionsWrapperService: InspectionsApiCorrectionsWrapperService,
    private inspectionsApiWrapperService: InspectionsApiWrapperService,
    private snackBarHandlingService: SnackBarHandlingService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    public customerGuidelinesService: CustomerGuidelinesService,
    public locationService: LocationService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.populateDeclineReasons();
    this.initializeFormGroup();
    if (data) {
      const correctionRequest: CorrectionRequest = data.correction;
      this.useShipmentDetailsCache = data.useShipmentDetailsCache;
      const dimensionerInspectionId: number = correctionRequest?.parentDimensionerInspectionId;
      this.correctionRequestId = correctionRequest?.correctionRequestId;
      this.correctionRequest$.next(correctionRequest);
      this.isReviewCorrectionActionExecutable$.next(correctionRequest?.reviewStatusCd === CorrectionRequestReviewStatusCd.IN_REVIEW);
      this.populateShipmentDetailsInformation(correctionRequest);

      this.inspectionsApiCorrectionsWrapperService.getDimensionerInspection(dimensionerInspectionId).pipe(take(1)).subscribe((resp) => {
        if(resp) {
         this.set75InchButton(resp);
        }
      })
    }
  }
  initializeFormGroup() {
    this.correctionFormGroup = new FormGroup({
      declineReason: new FormControl("", [Validators.required]),
      other: new FormControl(""),
    })
  }

  populateDeclineReasons() {
    this.inspectionsApiCorrectionsWrapperService.getCorrectionReasons(CorrectionReasonCategoryCd.CORR_RQST_DECLINE).subscribe((reasons) => {
      this.declineReasons = reasons.correctionReasons;
    })
  }

  onRedimChange(){
    this.redimEnabled = false;
    this.children.forEach((child)=> {
      child.children.forEach((childPro) => {
        if (childPro.redim){
          this.redimEnabled = true;
        }
      })
    })
  }

  populateShipmentDetailsInformation(correctionRequest: CorrectionRequest){
    if (this.useShipmentDetailsCache){
      const shipmentDetails = this.shipmentDetailsCacheService.shipmentDetails;
      const cachedShipmentDetailsPro = new ProNumber(shipmentDetails?.shipmentDetails?.shipmentId?.proNumber);
      const correctionsPro = new ProNumber(correctionRequest?.proNbr);
      if(cachedShipmentDetailsPro.toString() === correctionsPro.toString()){
        this.useShipmentDetailsResponse(shipmentDetails);
      } else {
        this.getNewShipmentDetails(correctionRequest);
      }
    } else {
      this.getNewShipmentDetails(correctionRequest);
    }
  }

  getNewShipmentDetails(correctionRequest) {
    this.inspectionShipmentDetailsService
      .getInspectionShipmentDetails(new ProNumber(correctionRequest?.proNbr))
      .pipe(take(1))
      .subscribe((inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) => {
        this.useShipmentDetailsResponse(inspectionShipmentDetailsResp);
        this.shipmentDetailsCacheService.shipmentDetails = inspectionShipmentDetailsResp;
      })
  }

  useShipmentDetailsResponse(inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp){
    this.inspectionShipmentDetailsRespSubject.next(inspectionShipmentDetailsResp);
    this.shipmentDetailsSubject.next(new InspectionShipmentDetails(inspectionShipmentDetailsResp?.shipmentDetails));
    this.displayDialogIfAny(inspectionShipmentDetailsResp);
  }

  private displayDialogIfAny(inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp): void {
    let notificationDataList: List<NotificationData> = List();
    const inspectionCustomerGuidelines = inspectionShipmentDetailsResp?.currentInspectionDetails?.custSpecificInspGuidelines;
    if (inspectionCustomerGuidelines?.length > 0) {
        const guideLineMessageList: List<string> =
        this.customerGuidelinesService.buildCustomerGuidelineMessagesList(inspectionCustomerGuidelines);
      notificationDataList = notificationDataList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.CUSTOMER_GUIDELINE_HEADER,
          guideLineMessageList
        )
      );
    }
    if (notificationDataList && !notificationDataList.isEmpty()) {
      const multipleNotificationData: MultipleNotificationData = new MultipleNotificationData(notificationDataList);
      const dialogConfig: MatDialogConfig = <MatDialogConfig>{
        data: multipleNotificationData
      };
      this.matDialog.open(CustomerInstructionsAndPalletPricingRatedDialogComponent, dialogConfig);
    }
  }
  
  set75InchButton(resp: GetDimensionerInspectionResp) {
    resp.dimensionerInspection.dimensionerInspectionRule.forEach(rule => {
      if (rule.ruleName === "Density75Inch"){
        this.inspectionsApiWrapperService
          .getCorrectionRequest(this.correctionRequestId).subscribe((result) => {
            if(result?.inspectionCorrectionRequest?.correctionRequest.correctionTypeCd != InspectionCorrectionTypeCd.ELS) {
              this.is75$.next(true);
              if (result?.inspectionCorrectionRequest?.correctionRequest?.effectiveHeightAppliedInd){
                this.apply75Button(false);
              } else {
                this.apply75Button(true);
              }
            }
          })
      }
    });
    this.correctionInformation$.next({dimensionerInspectionResp: resp});
    this.dimensionerInspectionDetails$.next(List(resp.dimensionerInspection?.dimensionerInspectionDetail));
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  reviewCommentsChanged(comments: string) {
    this.reviewComments = comments;
  }

  onReasonTypeChanged(selectedCorrectionType: string) {
    const otherField = this.correctionFormGroup.controls.other;
    if (selectedCorrectionType === "Other"){
      this.showOther = true;
      otherField.setValidators(Validators.required)
    } else {
      this.showOther = false;
      otherField.clearValidators();
    }
    otherField.updateValueAndValidity();
  }

  public onYesButtonClicked() {
    let correctionRequestReviewStatusCd: CorrectionRequestReviewStatusCd;

    switch (this.selectedAction) {
      case DialogAction.SAVE:
        correctionRequestReviewStatusCd = CorrectionRequestReviewStatusCd.IN_REVIEW;
        break;
      case DialogAction.APPROVE:
        correctionRequestReviewStatusCd = CorrectionRequestReviewStatusCd.APPROVED;
        break;
      case DialogAction.DECLINE:
        correctionRequestReviewStatusCd = CorrectionRequestReviewStatusCd.DECLINED;
        break;
      default:
        break;
    }

    this.inspectionsApiCorrectionsWrapperService.reviewCorrectionAction(this.reviewComments, correctionRequestReviewStatusCd, this.correctionRequestId, this.correctionFormGroup.controls.declineReason.value, this.correctionFormGroup.controls.other.value)
      .pipe(
        take(1)
      ).subscribe(() => {
        let message = `The correction request was ${toDialogActionPastTense(this.selectedAction as DialogAction)} successfully`;
        if (this.selectedAction === DialogAction.APPROVE){
          message = `The correction request was ${toDialogActionPastTense(this.selectedAction as DialogAction)} successfully and has been submitted`;
        }
        this.snackBarHandlingService.success(message);
        if(this.selectedAction === DialogAction.SAVE) {
          this.close(false, correctionRequestReviewStatusCd);
        } else {
          this.close(true, correctionRequestReviewStatusCd);
        }
      });
  }

  public onNoButtonClicked() {
    this.title = "Approve Auto-Correction";
    this.isConfirmMessageDisplayed$.next(false);
  }

  public close(update: boolean, action: string) {
    this.dialogRef.close({update, action});
  }
  
  onSelectAction(action: DialogAction) {
    this.isDecline$.next(action===DialogAction.DECLINE);
    if (action === DialogAction.DECLINE){
      this.title = "Decline Auto-Correction"
    } else {
      this.title = "Approve Auto-Correction";
    }
    this.selectedAction$.next(action)
    this.warningActionLabel$.next(`Are you sure you want to ${action} this Auto-Correction?`)
    this.isConfirmMessageDisplayed$.next(true);
  }

  onRedimension() {
    let correctionRequest: CorrectionRequest = undefined;
    let dimensionerInspectionDetailInput = undefined;
    let useParentPro: boolean = false;
    let pros: string[]= [];
    this.children.forEach((child)=> {
      correctionRequest = child.correctionRequest;
      dimensionerInspectionDetailInput = child?.dimensionerInspectionDetailListInput;
      child?.children?.forEach((childPro) => {
        if (childPro?.redim){
          pros.push(childPro?.dimensionerInspectionDetailInput?.childProNbr);
        }
      })
      if (pros.length === child?.children.length){ //all are selected
        useParentPro = true;
      }
    })
    if (useParentPro || (pros.length > 0 && pros[0] === undefined)){ // if all are selected or there is no child pros
      pros = [];
      pros[0] = correctionRequest.proNbr; // use parent pro
    }
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '30%';
    matDialogConfig.width = '30%';
    matDialogConfig.data = {
      correctionRequestId: correctionRequest.correctionRequestId,
      pros: pros
    };
    const matDialogRef = this.matDialog.open(RedimensionDialogComponent, matDialogConfig);

    matDialogRef.afterClosed().subscribe((result) => {
      if (result?.correctionRequests?.length > 0){
        this.snackBarHandlingService.success("Redimension request sucessfull.")
        this.close(true, CorrectionRequestReviewStatusCd.REDIMENSION);
      }
    });
  }

  on75Action(){
    this.inspectionsApiCorrectionsWrapperService.updateCorrectionRequestDimensions(true, this.correctionRequestId).subscribe((result) => {
      this.snackBarHandlingService.success(`The 75 inch rule was applied successfully`);
      this.apply75Button(false);
      this.emit75Event(result);
    });
  }

  off75Action(){
    this.inspectionsApiCorrectionsWrapperService.updateCorrectionRequestDimensions(false, this.correctionRequestId).subscribe((result) => {
      this.snackBarHandlingService.success(`The 75 inch rule was unapplied successfully`);
      this.apply75Button(true);
      this.emit75Event(result);
    });
  }

  apply75Button(on: boolean){
    this.is75RuleDisplayed$.next(on);
    this.isUnapply75RuleDisplayed$.next(!on);
  }

  emit75Event(result){
    this.correctionRequest$.next(result?.correctionRequest);
    this.new75Event.emit({id: result.correctionRequest.correctionRequestId});
  }
}
