<div class="correction-information">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Correction </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="isFinalDelivered" fxLayout="row" fxLayoutAlign="stretch start">
      <div fxLayout="column" fxFlex="20">
        <div class="label">Final Delivery Timestamp </div>
        <div class="value warning">
          {{ finalDeliveryDate }}
        </div>
      </div>
      <div fxLayout="column" fxFlex="20">
        <div class="label">Submit Correction By </div>
        <div class="value warning">
          {{ submitCorrectionBySubject | async}}
        </div>
      </div>
    </div>
      <div fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
        <div fxLayout="column" fxFlex="20">
          <div class="label">PRO</div>
          <div class="value" data-testid="pro">
            <a xpo-regular-link href="" (click)="onProLinkClicked($event)"
              [matTooltip]="'Navigate to '+proNbr?.formatProNumber()+' shipment details'">
              {{ proNbr?.formatProNumber() ?? '-' }}
            </a>
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Status</div>
          <div class="value">
            {{ CorrectionReviewStatusText[correctionRequest?.reviewStatusCd] ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Correction Type</div>
          <div class="value">
            {{ correctionRequest?.shortDescription ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Region</div>
          <div class="value">
            {{ region ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Sic</div>
          <div class="value">
            {{ correctionRequest?.requestorSicCd ?? '-' }}
          </div>
        </div>
      </div>
      <div *ngIf="is75">
        <div fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
          <div fxLayout="column" fxFlex="10">
            <div class="label"></div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="label">Weight</div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="label">Volume</div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="label">Density</div>
          </div>
          <div fxLayout="column" fxFlex="20">
            <div class="label">Revenue Impact</div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="stretch start">
          <div fxLayout="column" fxFlex="10">
            <div class="label">Actual</div>
            <div class="label">75 inch rule</div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ shipmentDetailsResp?.shipmentDetails?.totGrossWeight | number }}
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ volume?.toFixed(2) | number : '0.2'}}
            </div>
            <div class="value">
              {{ effectiveVolume?.toFixed(2) | number : '0.2' }}
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ correctionInformation?.dimensionerInspectionResp?.dimensionerInspection?.densityPsf?.toFixed(3) ?? 'N/A'}}
            </div>
            <div class="value">
              {{ correctionInformation?.dimensionerInspectionResp?.dimensionerInspection?.effectiveDensityPsf?.toFixed(3) ?? 'N/A'}}
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ correctionRequest?.previewRevenueAmount ?? '0' | currency}}
            </div>
            <div class="value">
              {{ correctionRequest?.previewEffectiveRevenueAmount ?? '0' | currency}}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!is75">
        <div fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
          <div fxLayout="column" fxFlex="20">
            <div fxLayout="row">
              <div fxLayout="column" style="min-width: 60px;">
                <div class="label">Weight</div>
                <div class="value">
                  {{ shipmentDetailsResp?.shipmentDetails?.totGrossWeight}}
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="20">
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <div fxLayout="column" style="min-width: 60px;">
                <div class="label" >Revenue Impact</div>
                <div class="value">
                  {{ correctionRequest?.previewRevenueAmount ?? '0' | currency}}
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="20">
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <div fxLayout="column" style="min-width: 60px;">
                <div class="label">Density</div>
                <div class="value">
                  {{ correctionInformation?.dimensionerInspectionResp?.dimensionerInspection?.densityPsf?.toFixed(3) ?? 'N/A'}}
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="20">
            <div fxLayout="row" fxLayoutAlign="stretch start">
              <div fxLayout="column" style="min-width: 60px;">
                <div class="label">Volume</div>
                <div class="value">
                  {{ volume?.toFixed(2) | number : '0.2'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
        <div class="label" style="font-weight: bold; font-size: 13px;">Commodities</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="10">
            <div class="label">Pieces</div> 
        </div>
        <div fxLayout="column" fxFlex="10">
            <div class="label" >Packaging</div>
        </div>
        <div fxLayout="column" fxFlex="10">
          <div class="label" >Weight</div>
        </div>
        <div fxLayout="column" fxFlex="10">
          <div class="label" >NMFC</div>
        </div>
        <div fxLayout="column" fxFlex="10">
          <div class="label" >Class</div>
        </div>
        <div fxLayout="column" fxFlex="50">
          <div class="label" >Commodity Description</div>
        </div>
      </div>
      <div style="padding-left: 400px;">
        <mat-progress-spinner
        *ngIf="showProgressSpinner"
        mode="indeterminate"
        [strokeWidth]="3"
        [diameter]="30"
        style="padding-left: 700;"
        class="notification__spinner"
      >
      </mat-progress-spinner></div>
      <div *ngFor="let commodity of shipmentDetailsResp?.shipmentDetails?.commodity" class="ins-preview_contents">
        <div fxLayout="row" fxLayoutAlign="stretch start">
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ commodity?.pieceCnt ?? 'N/A'}}
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ commodity?.packageCd ?? 'N/A'}}
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ commodity?.totGrossWeight ?? 'N/A'}}
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div *ngIf="commodity?.nmfcItemCd" class="value">
              <a xpo-regular-link href="" (click)="openNmftaSite(commodity?.nmfcItemCd, $event)">{{ commodity?.nmfcItemCd }}</a>
            </div>
            <div *ngIf="!commodity?.nmfcItemCd" class="value">
              N/A
            </div>
          </div>
          <div fxLayout="column" fxFlex="10">
            <div class="value">
              {{ commodity?.nmfcClass ?? 'N/A'}}
            </div>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div class="value">
              {{ commodity?.desc ?? 'N/A'}}
            </div>
          </div>
        </div>
      </div>
      <div  *ngIf="!showProgressSpinner" fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
        <div fxLayout="column" fxFlex="20">
          <div fxLayout="row" fxLayoutAlign="stretch start">
            <div fxLayout="column" style="min-width: 60px;">
              <div class="label">Bill To MAD Code </div>
              <div class="value">
                {{ shipmentDetailsResp?.shipmentDetails?.bil2AcctId?.acctMadCd ?? 'N/A'}}
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div fxLayout="row" fxLayoutAlign="stretch start">
            <div fxLayout="column" style="min-width: 60px;">
              <div class="label">Bill To Account Name </div>
              <div class="value">
                {{ shipmentDetailsResp?.shipmentDetails?.bil2AcctId?.acctName ?? 'N/A'}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
        <div fxLayout="column" fxFlex="100">
          <div class="label">Requestor Comments</div>
          <div class="value" data-testid="comments">
            {{ correctionRequest?.requestorComment ?? '-' }}
          </div>
        </div>
      </div>
      <div *ngIf="isDeclined" fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
        <div fxLayout="column" fxFlex="25">
          <div class="label">Decline Reason</div>
          <div class="value">
            {{ correctionRequest?.declineReasonDescription ?? '-' }}
          </div>
        </div>
        <div *ngIf="correctionRequest?.declineReasonUserDescription" fxLayout="column" fxFlex="75">
          <div class="label">Decline Comments</div>
          <div class="value">
            {{ correctionRequest?.declineReasonUserDescription ?? '-' }}
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start" class="row-padding">
        <div fxLayout="column" fxFlex="100">
          <div class="correction-information__comments">
            <div class="label">Review Comments</div>
            <mat-form-field>
              <textarea
                matInput
                placeholder=""
                maxlength="4000"
                [(ngModel)]="reviewComments"
                (focusout)="reviewCommentsChanged.emit(reviewComments)"
                [disabled]="isReviewCommentsFieldDisabled$ | async"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="100"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="100">
          <xpo-card type="data" color="">
            <xpo-card-header>
              <xpo-card-title>General Information</xpo-card-title>
            </xpo-card-header>
            <xpo-card-content>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="openDocuments($event)">View Documents</a>
              </div>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenCorrectionsApp($event)">Open Edge Corrections</a>
              </div>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenCorrectionRequestDialog($event)">Open Correction Request Details</a>
              </div>
              <div *ngIf="isRulesetAvailable" >
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenRulesets($event)">View Applied Rulesets</a>
              </div>
            </xpo-card-content>
          </xpo-card>
        </div>
        
      </div>
  </mat-expansion-panel>
</div>