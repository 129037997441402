import { Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { RedimensionRequestReasonCd } from '@xpo-ltl/sdk-common';
import { RedimensionCorrectionRequestShipmentRqst } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, catchError, EMPTY, Observable, Subject, take } from 'rxjs';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { InspectionsApiWrapperService } from '../../services/inspections/inspections-api-wrapper.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-redimension-dialog',
  templateUrl: './redimension-dialog.component.html',
  styleUrls: ['./redimension-dialog.component.scss']
})
export class RedimensionDialogComponent implements OnInit, OnDestroy {

  redimensionReasons =[];
  title: string = "Re-Dimensioning Reason Code";
  isManagerActionsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  redimensionFormGroup: FormGroup;

  private unsubscriber$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<RedimensionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inspectionsService: InspectionsApiWrapperService,
    private userService: UserService,
    private snackBarHandlingService: SnackBarHandlingService,
  ) {

  }

  ngOnInit() {
    for (let item in RedimensionRequestReasonCd){
      this.redimensionReasons.push({reasonCd: item, description: RedimensionRequestReasonCd[item]})
    }
    this.initializeFormGroup();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  initializeFormGroup() {
    this.redimensionFormGroup = new FormGroup({
      redimensionReason: new FormControl("", [Validators.required]),
    })
  }

  public onSaveClicked() {
    const reasonCode = RedimensionRequestReasonCd[this.redimensionFormGroup.controls.redimensionReason.value];
    const user = this.userService.currentUserEmployeeId;
    const correctionRequestId = this.data.correctionRequestId;
    this.inspectionsService.requestRedimension(correctionRequestId, this.data.pros, reasonCode, user).pipe(take(1),
    catchError((error) => {
      this.snackBarHandlingService.handleResponseError(error, ErrorMessageActions.UPDATING, `Redimension`);
      return EMPTY; 
    })
    ).subscribe((response) => {
      this.dialogRef.close(response);
    });
  }
  close() {
    this.dialogRef.close(undefined);
  }
}
