<xpo-card type="data" color="accent">
  <xpo-card-header>
    <xpo-card-title>Inspection information</xpo-card-title>
  </xpo-card-header>

  <xpo-card-content>
    <div class="first-row">
      <div class="left">
        <div class="requester-comments">
          <form *ngIf="inspectionInfoFormGroup" [formGroup]="inspectionInfoFormGroup">
            <div *ngIf="isReadOnlyView; else canModifyComment">
              <div class="labels">Requester Comments</div>
              <textarea
                rows="9"
                matInput
                value="{{ inspectionInfoFormGroup?.value?.requesterComment }}"
                disabled
                class="readonly_value"
              ></textarea>
              <div class="readonly_hint">Hover to the right corner of the text area to expand the section.</div>
            </div>
            <ng-template #canModifyComment>
              <mat-form-field
                floatLabel="always"
                hintLabel="Max character count: {{ maxChars }}, Currently used: {{
                  requesterCommentInput.value.length
                }}"
              >
                <mat-label>Requester Comments</mat-label>
                <textarea
                  rows="9"
                  formControlName="requesterComment"
                  matInput
                  #requesterCommentInput
                  [maxlength]="maxChars"
                  (ngModelChange)="onCommentChanged()"
                ></textarea>
                <mat-hint align="end">Hover to the right corner of the text area to expand the section.</mat-hint>
              </mat-form-field>
            </ng-template>
          </form>
        </div>
      </div>

      <div class="right">
        <div class="bol-info">
          <form *ngIf="inspectionInfoFormGroup" [formGroup]="inspectionInfoFormGroup">
            <div *ngIf="isReadOnlyView; else canModifyBolInfo">
              <div class="labels">Original Bill Of Landing Information</div>
              <textarea
                rows="3"
                matInput
                value="{{ inspectionInfoFormGroup?.value?.originalBolInfo }}"
                disabled
                class="readonly_value"
              ></textarea>
              <div class="readonly_hint">Hover to the right corner of the text area to expand the section.</div>
            </div>
            <ng-template #canModifyBolInfo>
              <mat-form-field
                floatLabel="always"
                hintLabel="Max character count: {{ maxChars }}, Currently used: {{ bolInfoInput.value.length }}"
              >
                <mat-label>Original Bill Of Lading Information</mat-label>
                <textarea
                  rows="3"
                  formControlName="originalBolInfo"
                  matInput
                  #bolInfoInput
                  [maxlength]="maxChars"
                  (ngModelChange)="onBolChanged()"
                ></textarea>
                <mat-hint align="end">Hover to the right corner of the text area to expand the section. </mat-hint>
              </mat-form-field>
            </ng-template>
          </form>
        </div>

        <div class="website">
          <form *ngIf="inspectionInfoFormGroup" [formGroup]="inspectionInfoFormGroup">
            <div *ngIf="isReadOnlyView; else canModifyWeb">
              <div *ngIf="inspectionInfoFormGroup?.value?.website?.length > 0">
                <div class="labels">Website</div>
                <a xpo-regular-link (click)="onOpenWebsite()" class="normal_height_row">{{
                  inspectionInfoFormGroup?.value?.website
                }}</a>
              </div>
            </div>
            <ng-template #canModifyWeb>
              <mat-form-field
                floatLabel="always"
                hintLabel="Max character count: {{ maxChars }}, Currently used: {{ websiteInput.value.length }}"
              >
                <mat-label>Website</mat-label>
                <textarea rows="3" formControlName="website" matInput #websiteInput [maxlength]="maxChars"></textarea>
                <mat-hint align="end">Hover to the right corner of the text area to expand the section. </mat-hint>
              </mat-form-field>

              <div *ngIf="inspectionInfoFormGroup?.value?.website?.length > 0" class="right">
                <a class="normal_height_row" xpo-regular-link color="accent" (click)="onOpenWebsite()"
                  >Open website in new tab</a
                >
              </div>
            </ng-template>
          </form>
        </div>
      </div>
    </div>

    <div class="second-row">
      <div *ngIf="!isReadOnlyView" class="add_photos">
        <div class="labels">New Inspection Photos ({{ (newPhotos$ | async)?.length }})</div>
        <div class="contents">
          <label class="photo-link" for="cameraInput">Add External Photo</label>
          <input
            type="file"
            accept="image/png, image/jpeg, image/gif"
            id="cameraInput"
            name="cameraInput"
            (change)="onPhotoSelectedFromFolder($event)"
            onclick="this.value=null"
            style="display: none"
            multiple="multiple"
          />

          <div class="gallery">
            <div *ngFor="let photo of newPhotos$ | async" class="photo">
              <img
                class="photo-source"
                [src]="photo?.getBase64data(true)"
                (click)="showPhotoGallery(photo.id)"
                [matTooltip]="'pro:' + photo?.id?.proNumber + ' fileName:' + photo?.fileName"
              />
              <div class="photo-link">
                <button mat-button (click)="onDeleteClicked(photo)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </xpo-card-content>
</xpo-card>
