<form *ngIf="redimensionFormGroup" [formGroup]="redimensionFormGroup">
  <div class="content-left">
    <h2 mat-dialog-title>{{ title }}</h2>
  </div>

  <div class="padded">
    <mat-form-field style="width: 80%">
      <mat-select
        formControlName="redimensionReason"
        placeholder="Select Re-dimension Reason"
        aria-modal>
        <mat-option
          *ngFor="let reason of redimensionReasons"
          [value]="reason.reasonCd">
          {{ reason.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="padded content-right">
    <button mat-button (click)="close()">Cancel</button>
    <button [disabled]="!redimensionFormGroup.valid"
      mat-button
      color="primary"
      (click)="onSaveClicked()"
    > Save </button>
  </div>
</form>
