import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { CorrectionRequest, DimensionerInspectionDetail } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject } from 'rxjs';
import { List } from 'immutable';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { SnackBarHandlingService } from '../../../../services/snack-bar-handling.service';
import { DimensionerInspectionDetailComponent } from '../dimensioner-inspection-detail/dimensioner-inspection-detail.component';
import { CorrectionRequestReviewStatusCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-dimensioner-inspection-detail-list',
  templateUrl: './dimensioner-inspection-detail-list.component.html',
  styleUrls: ['./dimensioner-inspection-detail-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionerInspectionDetailListComponent implements OnChanges {
  @Input()
  dimensionerInspectionDetailListInput: List<DimensionerInspectionDetail>;
  @Input()
  correctionRequest: CorrectionRequest;
  @Output() onRedimChange = new EventEmitter<any>();
  @ViewChildren(DimensionerInspectionDetailComponent) children: QueryList<DimensionerInspectionDetailComponent>;
  dimensionerInspectionDetailList$ = new BehaviorSubject<List<DimensionerInspectionDetail>>(List());
  isExpanded = true;
  matDialogRef: any;
  redimAll: boolean = false;

  constructor(
    private dialog: MatDialog,
    private snackBarHandlingService: SnackBarHandlingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dimensionerInspectionDetailListInput']) {
      this.dimensionerInspectionDetailList$.next(this.dimensionerInspectionDetailListInput);
    }
  }

  redimAllChanged(checked: boolean) {
    this.children.forEach((child) => {
      child.redimAllChanged(checked)
    })
    this.redimAll = checked;
    this.onRedimChange.emit(checked);
  }

  reCheckSelectAll(){
    let allChecked = true;
    this.children.forEach((child) => {
      if (!child.redim){
        allChecked = false;
      }
    })
    this.redimAll = allChecked;
    this.onRedimChange.emit(true);
  }

  isRedimAllowed() {
    return this.correctionRequest.reviewStatusCd === CorrectionRequestReviewStatusCd.IN_REVIEW
  }
}
