import { Injectable } from '@angular/core';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import {
  CreateInspectionCorrectionResp,
  CreateInspectionCorrectionRqst,
  CreateInspectionRqst,
  GetInspectionStatusHashPath,
  GetInspectionStatusHashResp,
  GetInspectionStatusPath,
  GetInspectionStatusResp,
  InspectionShipment,
  InspectionStatusHash,
  InspectionsApiService,
  ListEligibleInspectionCorrectionsPath,
  ListEligibleInspectionCorrectionsResp,
  ListInspectionCorrectionsQuery,
  ListInspectionCorrectionsResp,
  ListInspectionShipmentDetailsPath,
  ListInspectionShipmentDetailsQuery,
  ListInspectionShipmentDetailsResp,
  ListInspectionShipmentsPath,
  ListInspectionShipmentsQuery,
  ListInspectionShipmentsResp,
  ListPickupRequestsForInspectionPath,
  ListPickupRequestsForInspectionResp,
  ListPlannedShipmentsPath,
  ListPlannedShipmentsQuery,
  ListPlannedShipmentsResp,
  ListTrailerManifestForInspectionPath,
  ListTrailerManifestForInspectionQuery,
  ListTrailerManifestForInspectionResp,
  RefreshRecommendationsRqst,
  UpdateInspectionStatusResp,
  UpdateInspectionStatusRqst
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { BehaviorSubject, EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { ApiError } from '../../classes/error/api/api-error';
import { InspectionError } from '../../classes/error/inspection-error';
import { InspectionListItem } from '../../classes/inspection-list-item';
import { PlanningListItem } from '../../classes/planning-list-item';
import { ProNumber } from '../../classes/pronumber';
import { ApiUtil } from '../../classes/utils/api-util';
import { AddPro, AddProOverview } from '../../components/add-pro-detail/model/add-pro.model';
import { TrailerDetail } from '../../components/trailer-detail/model/trailer-detail.model';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { InspectionState } from '../../enums/inspection-state.enum';
import { ListName } from '../../enums/list-name.enum';
import { RetryStrategyService } from '../../operators/retry-strategy.service';
import { RequestValidator } from '../../validators/request.validator';
import { AppConstantsService } from '../app-constants.service';
import { SnackBarHandlingService } from '../snack-bar-handling.service';
import { InspectionsApiWrapperService } from '../inspections/inspections-api-wrapper.service';
import { RefreshDatesService } from '../refresh-dates.service';
import { UserService } from '../user.service';
import { LoadingOverlayService } from '../../components/loading-overlay/service/loading-overlay.service';
import { AppNavigationService } from '../app-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDetailsService {
  public static readonly DEFAULT_LOADING_MESSAGE = 'Loading. Please Wait...';
  public static readonly DEFAULT_PROCESSING_MESSAGE = 'Processing. Please Wait...';

  // Default Retry count
  public static readonly DEFAULT_RETRY_COUNT = 4;

  // Initial 15 second timeout for any standard GET (Single Object) response
  public static readonly DEFAULT_GET_TIMEOUT = 15000; // This will go up to 2 mins based on DEFAULT_RETRY_COUNT or 4...

  // Initial 30 second timeout for any standard GET (LIST) response
  public static readonly DEFAULT_LIST_TIMEOUT = 30000; // This will go up to 4 mins based on DEFAULT_RETRY_COUNT or 4...

  // long wait 60 second timeout. 
  public static readonly LONG_TIMEOUT = 60000;

  // Wait no longer than 60 seconds for any UPDATE (PUT, POST) response
  public static readonly DEFAULT_UPDATE_TIMEOUT = 60000;
  public static readonly DEFAULT_UPDATE_RETRY_COUNT = 0; // Don't retry on Updates

  // Wait no longer than 5 mins for Refresh Recommendations
  private static readonly DEFAULT_REFRESH_RECOMMENDATIONS_TIMEOUT = 300000;

  public static readonly DEFAULT_RETRY_DELAY = 1000; // retry every second on (non-timeout) errors

  private recommendedListDataSubject = new BehaviorSubject(<PlanningListItem[]>[]);
  recommendedListData$ = this.recommendedListDataSubject.asObservable();

  private flaggedListDataSubject = new BehaviorSubject(<PlanningListItem[]>[]);
  flaggedListData$ = this.flaggedListDataSubject.asObservable();

  private dismissedListDataSubject = new BehaviorSubject(<PlanningListItem[]>[]);
  dismissedListData$ = this.dismissedListDataSubject.asObservable();

  private inspectedListDataSubject = new BehaviorSubject(<InspectionListItem[]>[]);
  inspectedListData$ = this.inspectedListDataSubject.asObservable();

  private completedListDataSubject = new BehaviorSubject(<InspectionListItem[]>[]);
  completedListData$ = this.completedListDataSubject.asObservable();

  private inspectionDetailMapSubject = new BehaviorSubject(<Map<string, InspectionShipment>>undefined);
  inspectionDetailMap$ = this.inspectionDetailMapSubject.asObservable();

  private addProShipmentsSubject = new BehaviorSubject<AddProOverview>(undefined);
  addProShipments$ = this.addProShipmentsSubject.asObservable();

  // What is the date range for each list...
  // Default the Complete and Dismissed to 1 Day
  private listDateRangeMap: Map<ListName, string> = new Map([
    [ListName.COMPLETED, '1'],
    [ListName.DISMISSED, '1'],
    [ListName.INSPECTION_CORRECTIONS, '1']
  ]);

  // This map is used to determine if the list needs to be refreshed or not
  // Just before the call to refresh the lists, we will call the getStatusHashCodes operation
  // If the returned inspectionStateHashCode is different than what is stored in this map
  // The refresh the lists corresponding to the Inspection State(s) that changed
  private inspectionStateHashCodeMap = new Map<InspectionState, string>();

  public static readonly LIST_INS_SHM_STATUS_CDS_INSPECTED: InspectionState[] = [
    InspectionState.INSPECTED,
    InspectionState.IN_PROGRESS,
    InspectionState.EDITING
  ];
  public static readonly LIST_INS_SHM_STATUS_CD_FLAGGED: InspectionState = InspectionState.FLAGGED;
  public static readonly LIST_INS_SHM_STATUS_CD_RECOMMENDED: InspectionState = InspectionState.RECOMMENDED;

  private isLastNotSet: boolean = true;
  private _proNbrInputs: List<string>;

  constructor(
    private constants: AppConstantsService,
    private inspectionsApiService: InspectionsApiService,
    private inspectionsApiWrapperService: InspectionsApiWrapperService,
    private retryStrategy: RetryStrategyService,
    private errorHandling: SnackBarHandlingService,
    private refreshDatesService: RefreshDatesService,
    private userService: UserService,
    private loadingOverlayService: LoadingOverlayService,
    protected appNavigation: AppNavigationService
  ) {}

  get proNbrInputs(): List<string> {
    return this._proNbrInputs;
  }

  set proNbrInputs(proNbrInputs: List<string>) {
    this._proNbrInputs = List(proNbrInputs);
  }

  updateInspectionStatus(
    proNumber: ProNumber | Array<ProNumber>,
    inspectionState: InspectionState,
    warningAcceptedInd: boolean = false
  ): Observable<UpdateInspectionStatusResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(this.constants?.inspectionContext, 'Inspection Context');

    const request: UpdateInspectionStatusRqst = new UpdateInspectionStatusRqst();
    request.proNbr = new Array<string>();
    if (Array.isArray(proNumber)) {
      if (proNumber?.length > 0) {
        proNumber?.forEach((pro) => {
          RequestValidator.validateObjectNotUndefinedOrEmpty(pro, RequestValidator.PRO_NBR);
          RequestValidator.validateProNumber(pro);
          request.proNbr.push(pro.formatProNumber());
        });
      } else {
        throw new InspectionError(`${RequestValidator.PRO_NBR} ${RequestValidator.ERROR_CANNOT_BE_EMPTY}`);
      }
    } else {
      RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
      RequestValidator.validateProNumber(proNumber);
      request.proNbr.push(proNumber.formatProNumber());
    }
    request.statusCd = <string>inspectionState;
    request.inspectionContext = this.constants.inspectionContext;
    request.warningAcceptedInd = warningAcceptedInd;
    request.manuallyAddInd = false;

    return this.inspectionsApiService
      .updateInspectionStatus(request, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_UPDATE_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_UPDATE_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_PROCESSING_MESSAGE,
          'UPDATE-INSPECTION-STATUS'
        ),
        take(1)
      );
  }

  createInspection(inspectionShipment: InspectionShipment): Observable<void> {
    const request = new CreateInspectionRqst();
    request.inspection = inspectionShipment;
    request.inspection.inspectionContext = this.constants.inspectionContext;

    return this.inspectionsApiService
      .createInspection(request, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_UPDATE_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_UPDATE_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_PROCESSING_MESSAGE,
          'CREATE-INSPECTION'
        ),
        take(1)
      );
  }

  createInspectionCorrection(
    inspectionShipment: InspectionShipment,
    typeCode: string
  ): Observable<CreateInspectionCorrectionResp> {
    RequestValidator.validateStringNotNullOrEmpty(inspectionShipment?.shipmentId?.proNumber, 'proNumber');
    RequestValidator.validateStringNotNullOrEmpty(typeCode, 'typeCode');
    RequestValidator.validateObjectNotUndefinedOrEmpty(this.constants.inspectionContext, 'inspectionContext');

    const request: CreateInspectionCorrectionRqst = new CreateInspectionCorrectionRqst();
    request.proNbr = inspectionShipment.shipmentId.proNumber;
    request.correctionTypeCd = typeCode;
    request.inspectionContext = this.constants.inspectionContext;

    return this.inspectionsApiService.createInspectionCorrection(request, ApiUtil.DEFAULT_DATA_OPTIONS); // error is handled on component level
  }

  getInspectionDetailMap(proNbrs: string[]): Observable<Map<string, InspectionShipment>> {
    const pathParam = new ListInspectionShipmentDetailsPath();
    const queryParam = new ListInspectionShipmentDetailsQuery();

    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParam.shiftCd = this.constants.inspectionContext?.shiftCd;
    queryParam.proNbr = proNbrs;

    return this.inspectionsApiService
      .listInspectionShipmentDetails(pathParam, queryParam, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        map((resp) => this.convertArrayToMap(resp.shipmentDetails)),
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'INSPECTION-DETAIL-MAP'
        ),
        take(1)
      );
  }

  getInspectionStatus(proNumber: ProNumber): Observable<GetInspectionStatusResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);

    const request: GetInspectionStatusPath = new GetInspectionStatusPath();
    request.proNumber = proNumber.formatProNumber();
    request.inspectionSic = this.constants.inspectionContext?.inspectionSic;

    return this.inspectionsApiService
      .getInspectionStatus(request, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-INSPECTION-STATUS'
        ),
        take(1)
      );
  }

  getListDateRange(listName: ListName): string {
    return this.listDateRangeMap.get(listName);
  }

  listEligibleInspectionCorrections(aShipmentInstId: string): Observable<ListEligibleInspectionCorrectionsResp> {
    RequestValidator.validateStringNotNullOrEmpty(aShipmentInstId, 'shipmentInstId');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');

    const shipmentInstId: number = +aShipmentInstId;
    const path: ListEligibleInspectionCorrectionsPath = new ListEligibleInspectionCorrectionsPath();
    path.shipmentInstId = shipmentInstId;
    path.inspectionSicCd = this.constants?.inspectionContext?.inspectionSic;

    return this.inspectionsApiService.listEligibleInspectionCorrections(path, ApiUtil.DEFAULT_DATA_OPTIONS); // error is handled on component level
  }

  listInspectionDetail(proNbrs: List<string>, showOnToastError = true): Observable<AddProOverview> {
    const pathParam = new ListInspectionShipmentDetailsPath();
    const queryParam = new ListInspectionShipmentDetailsQuery();

    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParam.shiftCd = this.constants.inspectionContext?.shiftCd;
    queryParam.proNbr = Array.from(proNbrs);

    return this.inspectionsApiService
      .listInspectionShipmentDetails(pathParam, queryParam, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'LIST-INSPECTION-DETAIL'
        ),
        take(1),
        map((resp: ListInspectionShipmentDetailsResp) => {
          const addProOverview: AddProOverview = new AddProOverview();
          addProOverview.addPros = List(AddPro.mapInspectionShipments(resp.shipmentDetails));
          addProOverview.dataValidationErrors = List(resp.dataValidationErrors);
          this.addProShipmentsSubject.next(addProOverview);
          return addProOverview;
        }),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Inspection shipment details`);

          return EMPTY;
        })
      );
  }

  listPickupRequestsForInspection(): Observable<ListPickupRequestsForInspectionResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');

    const pathParam: ListPickupRequestsForInspectionPath = new ListPickupRequestsForInspectionPath();
    pathParam.sicCd = this.constants.inspectionContext.inspectionSic;

    return this.inspectionsApiService.listPickupRequestsForInspection(pathParam, ApiUtil.DEFAULT_DATA_OPTIONS);
  }

  listTrailerManifestForInspection(
    shift: ShiftCd,
    inspectionSic: string,
    doorNbr: string,
    trailerNbr?: string
  ): Observable<TrailerDetail> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(shift, 'Shift Code');
    RequestValidator.validateStringNotNullOrEmpty(inspectionSic, 'Inspection Sic');
    if (doorNbr?.trim().length > 0) {
      RequestValidator.validateStringNotNullOrEmpty(doorNbr, 'Door number');
    } else {
      RequestValidator.validateStringNotNullOrEmpty(trailerNbr, 'Trailer number');
    }

    const requestPath = new ListTrailerManifestForInspectionPath();
    requestPath.shiftCd = shift;
    requestPath.sicCd = inspectionSic;
    const requestQUery = new ListTrailerManifestForInspectionQuery();
    requestQUery.doorNbr = doorNbr;
    requestQUery.trailerNbr = trailerNbr;

    return this.inspectionsApiService
      .listTrailerManifestForInspection(requestPath, requestQUery, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(map((resp: ListTrailerManifestForInspectionResp) => resp as TrailerDetail));
  }

  loadAllLists(forceRefreshAllLists: boolean = false): void {
    // Don't load anything if we don't have an inspection context yet
    if (!this.constants.inspectionContext?.inspectionSic) {
      return;
    }

    this.loadingOverlayService.setIsLoading(true);

    this.getInspectionStatusHash()
      .pipe(
        take(1),
        catchError((error) => {
          // if error, just update all lists
          forkJoin(
            this.updateRecommendedAndFlaggedShipments(),
            this.updateInspectedShipments(),
            this.updateCompletedShipments(),
            this.updateDismissedShipments()
          )
            .pipe(take(1))
            .subscribe((res) => {
              this.loadingOverlayService.setIsLoading(false);
            });

          throw error;
        })
      )
      .subscribe((inspectionStatusHashCodes: Map<InspectionState, string>) => {
        let changedLists: Set<ListName>;
        if (forceRefreshAllLists) {
          // update the lastRefreshDate if we're forcing a full refresh...
          changedLists = this.getChangedListsByHashCode(null); // Passing in null will refresh all lists
        } else {
          if (this.isLastNotSet) {
            this.isLastNotSet = false;
          }
          changedLists = this.getChangedListsByHashCode(inspectionStatusHashCodes);
        }
        // Set inspectionStateHashCode Map to new one
        this.inspectionStateHashCodeMap = inspectionStatusHashCodes;
         this.appNavigation.listShipmentsTab$.pipe(take(1)).subscribe((tabName) => {
           if (tabName === ListName.DISMISSED){
            this.updateDismissedShipments().subscribe();
           }
         });

        // Per change WI-2371 - https://jira.xpo.com/browse/WI-2371
        // For now, we decided to refresh the Recommended and Flagged Lists
        // everytime instead of waiting for a List Data change
        forkJoin(
          this.updateRecommendedAndFlaggedShipments(),
          changedLists.has(ListName.INSPECTED) ? this.updateInspectedShipments() : of(true),
          changedLists.has(ListName.COMPLETED) ? this.updateCompletedShipments() : of(true),
        )
          .pipe(
            take(1),
            finalize(() => this.loadingOverlayService.setIsLoading(false))
          )
          .subscribe();
      });
  }

  manuallyAddShipment(proNumber: ProNumber): Observable<boolean> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(this.constants?.inspectionContext, 'Inspection Context');
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);

    const request: UpdateInspectionStatusRqst = new UpdateInspectionStatusRqst();
    request.proNbr = [proNumber.formatProNumber()];
    request.statusCd = null; // null Status code, means don't change existing status
    request.inspectionContext = this.constants.inspectionContext;
    request.warningAcceptedInd = false;
    request.manuallyAddInd = true;

    return this.inspectionsApiService.updateInspectionStatus(request, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_UPDATE_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_UPDATE_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_PROCESSING_MESSAGE,
        'UPDATE-INSPECTION-STATUS'
      ),
      take(1),
      map(
        (response: UpdateInspectionStatusResp) => {
          if (!response) {
            throw new ApiError('Unknown Error: UpdateInspectionStatusResp is empty.');
          }
          if (response?.validationErrors?.length > 0) {
            throw new ApiError(`Validation Error: ${response.validationErrors[0].message}`);
          }

          return true;
        },
        (error) => {
          throw error;
        }
      )
    );
  }

  refreshRecommendations(): Observable<void> {
    const request: RefreshRecommendationsRqst = new RefreshRecommendationsRqst();
    request.inspectionContext = this.constants.inspectionContext;

    return this.inspectionsApiService.refreshRecommendations(request, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_REFRESH_RECOMMENDATIONS_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        0,
        'Refreshing Recommendations. Please Wait...',
        'REFRESH-RECOMMENDATIONS'
      ) // Don't ever retry
    );
  }

  // Update list range and force refresh of list
  setListDateRange(listName: ListName, dateRangeValue: string) {
    this.listDateRangeMap.set(listName, dateRangeValue);
    if (listName === ListName.DISMISSED) {
      this.updateDismissedShipments().pipe(take(1)).subscribe();
    } else if (listName === ListName.COMPLETED) {
      this.updateCompletedShipments().pipe(take(1)).subscribe();
    }
  }

  listInspectionCorrections(
    sicOrPro: string,
    startDate: Date,
    endDate: Date,
    isSearchForSic: boolean,
    filterAutoDensityInd: boolean,
    filterAutoElsInd: boolean
  ): Observable<ListInspectionCorrectionsResp> {
    RequestValidator.validateStringNotNullOrEmpty(sicOrPro, 'Sic or Pro');

    const requestQUery = new ListInspectionCorrectionsQuery();
    ProNumber.isValid(sicOrPro) ? (requestQUery.proNbr = sicOrPro) : (requestQUery.sicCd = sicOrPro);

    const isSearchForUser: boolean = !isSearchForSic;
    let userEmployeeId: string;
    if (isSearchForUser) {
      userEmployeeId = this.userService.currentUserEmployeeId;
    }
    requestQUery.employeeId = userEmployeeId;

    if (!startDate) {
      const today: Date = new Date();
      startDate = new Date(
        today.setDate(today.getDate() - +this.listDateRangeMap.get(ListName.INSPECTION_CORRECTIONS))
      );
    }

    if (!endDate) {
      endDate = new Date();
    }

    requestQUery.startDate = startDate?.toLocaleDateString('es-PA');
    requestQUery.endDate = endDate?.toLocaleDateString('es-PA');

    requestQUery.filterAutoDensityInd = filterAutoDensityInd;
    requestQUery.filterAutoElsInd = filterAutoElsInd;

    return this.inspectionsApiService.listInspectionCorrections(requestQUery, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      tap((resp: ListInspectionCorrectionsResp) => {
        if (resp) {
          this.refreshDatesService.setInspectionCorrectionListRefreshTimestamp(new Date());
        }
      })
    );
  }

  private convertArrayToMap(arr: InspectionShipment[]): Map<string, InspectionShipment> {
    const detailMap = new Map<string, InspectionShipment>();
    arr.forEach((detail) => detailMap.set(detail.shipmentId.proNumber, detail));
    this.inspectionDetailMapSubject.next(detailMap);
    return detailMap;
  }

  private getChangedListsByHashCode(newInspectionStateHashCodeValues: Map<InspectionState, string>): Set<ListName> {
    const changedLists = new Set<ListName>();
    // return all Lists if Map Parameter doesn't exist
    if (!newInspectionStateHashCodeValues) {
      changedLists.add(ListName.RECOMMENDED);
      changedLists.add(ListName.FLAGGED);
      changedLists.add(ListName.DISMISSED);
      changedLists.add(ListName.INSPECTED);
      changedLists.add(ListName.COMPLETED);
      return changedLists;
    }
    // check each status one by one
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.RECOMMENDED),
        this.inspectionStateHashCodeMap.get(InspectionState.RECOMMENDED)
      )
    ) {
      changedLists.add(ListName.RECOMMENDED);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.FLAGGED),
        this.inspectionStateHashCodeMap.get(InspectionState.FLAGGED)
      )
    ) {
      changedLists.add(ListName.FLAGGED);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.DISMISSED),
        this.inspectionStateHashCodeMap.get(InspectionState.DISMISSED)
      )
    ) {
      changedLists.add(ListName.DISMISSED);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.INSPECTED),
        this.inspectionStateHashCodeMap.get(InspectionState.INSPECTED)
      ) ||
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.IN_PROGRESS),
        this.inspectionStateHashCodeMap.get(InspectionState.IN_PROGRESS)
      ) ||
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.EDITING),
        this.inspectionStateHashCodeMap.get(InspectionState.EDITING)
      )
    ) {
      changedLists.add(ListName.INSPECTED);
    }
    if (
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.INSPECTED_NOT_CORRECTED),
        this.inspectionStateHashCodeMap.get(InspectionState.INSPECTED_NOT_CORRECTED)
      ) ||
      this.isHashCodeDifferent(
        newInspectionStateHashCodeValues.get(InspectionState.CORRECTION_SUBMITTED),
        this.inspectionStateHashCodeMap.get(InspectionState.CORRECTION_SUBMITTED)
      )
    ) {
      changedLists.add(ListName.COMPLETED);
    }

    return changedLists;
  }

  private updateCompletedShipments() {
    const dateRangeValue = this.listDateRangeMap.get(ListName.COMPLETED);
    let fromDate: number;
    if (dateRangeValue) {
      fromDate = new Date().getTime() - +dateRangeValue * 24 * 60 * 60 * 1000; // turn days into fromDate now - (days*ms per day)
    }

    return this.listInspectedShipments(
      [InspectionState.CORRECTION_SUBMITTED, InspectionState.INSPECTED_NOT_CORRECTED],
      fromDate
    ).pipe(
      tap((response: ListInspectionShipmentsResp) => {
        if (response) {
          const inspectionListItemsArray: InspectionListItem[] = InspectionListItem.mapInspectionShipments(
            response.inspectionShipments
          );
          InspectionListItem.sortArray(inspectionListItemsArray, ListName.COMPLETED);
          this.completedListDataSubject.next(inspectionListItemsArray);
        }
      })
    );
  }

  public updateDismissedShipments() {
    const dateRangeValue = this.listDateRangeMap.get(ListName.DISMISSED);
    let fromDate: number;
    if (dateRangeValue) {
      fromDate = new Date().getTime() - +dateRangeValue * 24 * 60 * 60 * 1000; // turn days into fromDate now - (days*ms per day)
    }

    return this.getPlannedShipments(InspectionState.DISMISSED, fromDate).pipe(
      tap((response) => {
        if (response) {
          const planningListItemsArray: PlanningListItem[] = PlanningListItem.mapInspectionShipments(
            response.plannedShipments
          );
          PlanningListItem.sortArray(planningListItemsArray, ListName.DISMISSED);
          this.dismissedListDataSubject.next(planningListItemsArray);
        }
      })
    );
  }

  private setFlaggedShipments(plannedShipments: InspectionShipment[]) {
    const planningListItemsArray: PlanningListItem[] = PlanningListItem.mapInspectionShipments(plannedShipments);
    PlanningListItem.sortArray(planningListItemsArray, ListName.FLAGGED);
    this.flaggedListDataSubject.next(planningListItemsArray);
  }

  private isHashCodeDifferent(hashCode1: string, hashCode2: string): boolean {
    let value1 = '';
    let value2 = '';
    if (hashCode1 != null) {
      value1 = hashCode1;
    }
    if (hashCode2 != null) {
      value2 = hashCode2;
    }
    return value1 !== value2;
  }

  private updateInspectedShipments() {
    return this.listInspectedShipments(ShipmentDetailsService.LIST_INS_SHM_STATUS_CDS_INSPECTED).pipe(
      tap((response: ListInspectionShipmentsResp) => {
        if (response) {
          const inspectionListItemsArray: InspectionListItem[] = InspectionListItem.mapInspectionShipments(
            response.inspectionShipments
          );
          InspectionListItem.sortArray(inspectionListItemsArray, ListName.INSPECTED);
          this.inspectedListDataSubject.next(inspectionListItemsArray);
        }
      })
    );
  }

  private getInspectionStatusHash(): Observable<Map<InspectionState, string>> {
    if (
      !this.constants?.inspectionContext?.inspectionSic ||
      this.constants?.inspectionContext?.inspectionSic?.trim().length === 0
    ) {
      return; // Just complete.. no next value(Note from Matthew-Havlovick)
    }

    const pathParams: GetInspectionStatusHashPath = new GetInspectionStatusHashPath();
    pathParams.inspectionSic = this.constants.inspectionContext?.inspectionSic;

    return this.inspectionsApiService.getInspectionStatusHash(pathParams, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        0, // don't retry
        0, // don't retry
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-INSPECTION-STATUS-HASH-CODES'
      ),
      take(1),
      map((response: GetInspectionStatusHashResp) => {
        if (!response) {
          return; // Just complete.. no next value(Note from Matthew-Havlovick)
        }
        const inspectionStatusHashCodes = new Map<InspectionState, string>();
        response.inspectionStatusHashes?.forEach((value: InspectionStatusHash) => {
          inspectionStatusHashCodes.set(<InspectionState>value.inspectionStatusMessage, value.hashCode);
        });
        return inspectionStatusHashCodes;
      })
    );
  }

  private getPlannedShipments(
    statusCodes: InspectionState | Array<InspectionState>,
    fromDate: number = null
  ): Observable<ListPlannedShipmentsResp> {
    RequestValidator.validateArrayOrSingleObjectNotEmpty(statusCodes, 'Status Code');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    const codes = Array.isArray(statusCodes) ? statusCodes : [statusCodes];
    const pathParam: ListPlannedShipmentsPath = new ListPlannedShipmentsPath();
    const queryParams = new ListPlannedShipmentsQuery();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParams.shiftCd = this.constants.inspectionContext?.shiftCd;
    queryParams.plannedShipmentStatusCds = <string[]>codes;

    if (fromDate) {
      queryParams.fromDt = new Date(fromDate);
    }

    const hasRecommendedStatus: boolean =
      queryParams.plannedShipmentStatusCds.indexOf(InspectionState.RECOMMENDED) > -1;

    return this.inspectionsApiService.listPlannedShipments(pathParam, queryParams, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategy.retryStrategy(
        ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-PLANNED-SHIPMENTS-' + codes // Need to append codes so the stats update correctly
      ),
      take(1),
      finalize(() => {
        if (hasRecommendedStatus) {
          this.inspectionsApiWrapperService.updateLastRefreshRecommendationsDate();
        }
      })
    );
  }


  private setRecommendedShipments(plannedShipments: InspectionShipment[]) {
    const planningListItemsArray: PlanningListItem[] = PlanningListItem.mapInspectionShipments(plannedShipments);
    PlanningListItem.sortArray(planningListItemsArray, ListName.RECOMMENDED);
    this.recommendedListDataSubject.next(planningListItemsArray);
  }

  private listInspectedShipments(
    statusCodes: InspectionState | Array<InspectionState>,
    fromDate: number = null
  ): Observable<ListInspectionShipmentsResp> {
    RequestValidator.validateArrayOrSingleObjectNotEmpty(statusCodes, 'Status Code');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.shiftCd, 'Shift Code');

    const codes: InspectionState[] = Array.isArray(statusCodes) ? statusCodes : [statusCodes];
    const pathParam: ListInspectionShipmentsPath = new ListInspectionShipmentsPath();
    const queryParams: ListInspectionShipmentsQuery = new ListInspectionShipmentsQuery();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    queryParams.inspectionStatusCodes = <string[]>codes;
    queryParams.shiftCd = this.constants.inspectionContext?.shiftCd;
    if (fromDate) {
      queryParams.fromDt = new Date(fromDate);
    }

    return this.inspectionsApiService
      .listInspectionShipments(pathParam, queryParams, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_LIST_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-INSPECTED-SHIPMENTS-' + codes // Need to append codes so the stats update correctly
        ),
        take(1)
      );
  }

  /**
   * https://xpo.atlassian.net/browse/WI-2404
   *
   * Refresh should be forced even if hash is the same
   *
   * As an inspector I want to see accurate and timely location status of shipments for inspection in my Flagged and
   * Recommendations lists.  I want to have those list auto-refreshed anytime there is a change to reflect current
   * locations which can change quickly at the terminals
   * @private
   */
  private updateRecommendedAndFlaggedShipments() {
    return this.getPlannedShipments([
      ShipmentDetailsService.LIST_INS_SHM_STATUS_CD_FLAGGED,
      ShipmentDetailsService.LIST_INS_SHM_STATUS_CD_RECOMMENDED
    ]).pipe(
      tap((response) => {
        this.refreshDatesService.setShipmentListRefreshDate(new Date());

        if (response?.plannedShipments) {
          const recommendedShm: InspectionShipment[] = [];
          const flaggedShm: InspectionShipment[] = [];

          for (let i = 0; i < response.plannedShipments.length; i++) {
            const inspectionShipment: InspectionShipment = response.plannedShipments[i];
            if (inspectionShipment.inspectionStatusCd === ShipmentDetailsService.LIST_INS_SHM_STATUS_CD_FLAGGED) {
              flaggedShm.push(inspectionShipment);
            } else if (
              inspectionShipment.inspectionStatusCd === ShipmentDetailsService.LIST_INS_SHM_STATUS_CD_RECOMMENDED
            ) {
              recommendedShm.push(inspectionShipment);
            } else {
              const msg: string = 'received inspectionShipment.inspectionStatusCd is not treated: ';
              console.error(msg, inspectionShipment.inspectionStatusCd);
              throw new InspectionError(msg);
            }
          }
          this.setRecommendedShipments(recommendedShm);
          this.setFlaggedShipments(flaggedShm);
        }
      })
    );
  }
}
