import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { WebcamModule } from 'ngx-webcam';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { CameraComponent } from '../components/camera/camera.component';
import { CustomerGuidelinesFormComponent } from '../components/customer-guidelines/customer-guidelines-form/customer-guidelines-form.component';
import { CustomerGuidelinesModule } from '../components/customer-guidelines/customer-guidelines.module';
import { RecommendationRulesModule } from '../components/recommendation-rules/recommendation-rules.module';
import { RecommendedRulesFormComponent } from '../components/recommendation-rules/recommended-rules-form/recommended-rules-form.component';
import { RecommendedRulesSummaryComponent } from '../components/recommendation-rules/recommended-rules-summary/recommended-rules-summary.component';
import { WebCameraComponent } from '../components/web-camera/web-camera.component';
import { MaterialModule } from '../material.module';
import { XpoPipesModule } from '../pipes/xpo-pipes.module';
import { InspectionsDialogsService } from '../services/inspections-dialogs.service';
import { AddProComponent } from './add-pro/add-pro.component';
import { InvalidProNumberComponent } from './add-pro/invalid-pro-number/invalid-pro-number.component';
import { BroadcastMessageDialogComponent } from './broadcast-message-dialog/broadcast-message-dialog.component';
import { CameraDialogComponent } from './camera-dialog/camera-dialog.component';
import { ChangeLocationComponent } from './change-location/change-location.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { CustomerGuidelineDialogComponent } from './customer-guideline-dialog/customer-guideline-dialog.component';
import { CustomerInstructionsAndPalletPricingRatedDialogComponent } from './customer-instructions-dialog/customer-instructions-dialog.component';
import { DevOnlyComponent } from './dev-only/dev-only.component';
import { IFrameDisplayComponent } from './iframe-display/iframe-display.component';
import { InspectionDetailDialogComponent } from './inspection-detail-dialog/inspection-detail-dialog.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { RecommendationRuleDialogComponent } from './recommendation-rule-dialog/recommendation-rule-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { StatusChangeDialogComponent } from './status-change-dialog/status-change-dialog.component';
import { ToolsMenuComponent } from './tools-menu/tools-menu.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { UnreadBroadcastMessageDialogComponent } from './unread-broadcast-message-dialog/unread-broadcast-message-dialog.component';
import { UnreadBroadcastMessageItemComponent } from './unread-broadcast-message-dialog/unread-broadcast-message-item/unread-broadcast-message-item.component';
import { CorrectionApprovalDialogComponent } from './correction-approval-dialog/correction-approval-dialog.component';
import { InspectionsApiCorrectionsWrapperService } from '../services/api/inspections-api-corrections-wrapper.service';
import { CorrectionInformationComponent } from './correction-approval-dialog/components/correction-information';
import { DimensionerInspectionDetailComponent } from './correction-approval-dialog/components/dimensioner-inspection-detail';
import { DimensionerInspectionDetailListComponent } from './correction-approval-dialog/components/dimensioner-inspection-detail-list/dimensioner-inspection-detail-list.component';
import { InspectionCorrectionsCardComponent } from '../components/inspection-corrections-card/inspection-corrections-card.component';
import { CustomerInstructionsComponent } from '../components/customer-instructions/customer-instructions.component';
import { LocationService } from '../services/location/location.service';
import { PreviousInspectionComponent } from '../components/previous-inspection/previous-inspection.component';
import { RedimensionDialogComponent } from './redimension-dialog/redimension-dialog.component';

const components = [
  ToolsMenuComponent,
  AddProComponent,
  ConfirmCancelComponent,
  ChangeLocationComponent,
  StatusChangeDialogComponent,
  SettingsComponent,
  RecommendationRuleDialogComponent,
  IFrameDisplayComponent,
  RecommendedRulesFormComponent,
  RecommendedRulesSummaryComponent,
  CustomerGuidelineDialogComponent,
  CorrectionApprovalDialogComponent,
  CustomerGuidelinesFormComponent,
  CustomerInstructionsAndPalletPricingRatedDialogComponent,
  NotesDialogComponent,
  RedimensionDialogComponent,
  CameraDialogComponent,
  CameraComponent,
  BroadcastMessageDialogComponent,
  UnreadBroadcastMessageDialogComponent,
  UnreadBroadcastMessageItemComponent,
  DevOnlyComponent,
  WebCameraComponent,
  InvalidProNumberComponent,
  CorrectionInformationComponent,
  DimensionerInspectionDetailComponent,
  DimensionerInspectionDetailListComponent,
  InspectionDetailDialogComponent,
];
@NgModule({
  declarations: components,
  imports: [
    MatProgressSpinnerModule,
    MaterialModule,
    XpoPipesModule,
    RecommendationRulesModule,
    CustomerGuidelinesModule,
    WebcamModule,
    CustomerInstructionsComponent,
    InspectionCorrectionsCardComponent,
    PreviousInspectionComponent,
    MatTooltipModule,
    MatSelectModule,
    XpoCardModule
  ],
  providers: [InspectionsDialogsService, InspectionsApiCorrectionsWrapperService, LocationService],
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogsModule {}
