<div class="image-gallery">
  <div class="mat-camera-icon">
    <button class="photo-btn" mat-button (click)="launchCamera()">
      <mat-icon class="photo-btn" svgIcon="photo-icon"></mat-icon>
    </button>
  </div>
</div>

<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title class="section-label"> New Photos ({{ (photos$ | async)?.length }})</mat-panel-title>
  </mat-expansion-panel-header>

  <div>
    <label class="photo-text-color" for="cameraInput">Add External Photo</label>
    <input
      type="file"
      accept="image/png, image/jpeg, image/gif"
      id="cameraInput"
      name="cameraInput"
      (change)="externalPicture($event)"
      onclick="this.value=null"
      style="display: none"
      multiple="multiple"
    />
    <div class="image-gallery__frame-box" fxLayout="row wrap" fxLayoutGap="10px">
      <div *ngFor="let photo of photos$ | async" fxLayout="column">
        <div fxFlex>
          <img
            class="image-gallery__image-content"
            [src]="photo?.getBase64data(true)"
            (click)="showPhotoGallery(photo.id)"
            [matTooltip]="'pro:' + photo.id?.proNumber + ' fileName:' + photo.fileName"
          />
        </div>
        <div class="photo-text-color">
          <button mat-button (click)="deletePhoto(photo)">Delete</button>
        </div>
      </div>
      <mat-progress-spinner
      *ngIf="showProgressSpinner"
      mode="indeterminate"
      [strokeWidth]="3"
      [diameter]="30"
      class="notification__spinner"
    >
    </mat-progress-spinner>
    </div>
  </div>
</mat-expansion-panel>
