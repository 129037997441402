<div class="dimensioner-inspection-detail-list">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label">Dimensioner Inspection Details ({{ (dimensionerInspectionDetailList$ | async)?.size }})</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="isRedimAllowed()" style="float: right; padding-right: 60px;">
      <mat-checkbox  [checked]="redimAll" (change)="redimAllChanged($event.checked)">
      </mat-checkbox>
      Re-Dimension All
    </div>
    <div *ngIf="isRedimAllowed()" style="padding-top: 40px;"></div>
    <app-dimensioner-inspection-detail *ngFor="let dimensionerInspectionDetail of dimensionerInspectionDetailList$ | async" [dimensionerInspectionDetailInput]="dimensionerInspectionDetail" [correctionRequest]="correctionRequest"  (onRedimChanged)="reCheckSelectAll()"></app-dimensioner-inspection-detail>
  </mat-expansion-panel>
</div>